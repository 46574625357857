import React, { useEffect, useRef, useState } from 'react';

const NumberInfoBanner = ({ infoBannerItems }) => {

    const [inView, setInView] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true);
                }
            },
            {
                threshold: 0.3,
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div className='w-full grid grid-cols-4 pt-8 pb-13 divide-x divide-c_info_banner_divider'
             ref={ref}
        >
            {/* {infoBannerItems?.map((infoBannerItem, index) => */}
                <div className={`min-h-fit flex flex-row justify-center py-9 px-4`} >
                    <div className='flex flex-col justify-center pe-4'>
                        <h1 className='pb-2 font-bold text-c_info_banner_text text-5xl'>
                            {inView && <span className={`animate-[counter_2s_ease-out_forwards] tabular-nums before:content-[counter(num)] [counter-set:_num_var(--num-dealers)] `}>
                                <span class="sr-only">{infoBannerItems[0].title}</span>
                                +
                            </span>}
                        </h1>
                        <p className=' text-lg font-bold text-white md:text-4xl lg:text-4xl'>
                            {infoBannerItems[0].description}
                        </p>
                    </div>
                    <div className='overflow-hidden flex flex-col h-full w-fit justify-center'>
                        <img
                            className='object-contain h-20'
                            src={infoBannerItems[0].image}
                            alt=''
                        >
                        </img>
                    </div>
                </div>
                <div className={`min-h-fit flex flex-row justify-center py-9 px-4`} >
                    <div className='flex flex-col justify-center pe-4'>
                        <h1 className='pb-2 font-bold text-c_info_banner_text text-5xl'>
                            {inView && <span className={`animate-[counter_2s_ease-out_forwards] tabular-nums before:content-[counter(num)] [counter-set:_num_var(--num-brands)] `}>
                                <span class="sr-only">{infoBannerItems[1].title}</span>
                                +
                            </span>}
                        </h1>
                        <p className=' text-lg font-bold text-white md:text-4xl lg:text-4xl'>
                            {infoBannerItems[1].description}
                        </p>
                    </div>
                    <div className='overflow-hidden flex flex-col h-full w-fit justify-center'>
                        <img
                            className='object-contain h-20'
                            src={infoBannerItems[1].image}
                            alt=''
                        >
                        </img>
                    </div>
                </div>
                <div className={`min-h-fit flex flex-row justify-center py-9 px-4`} >
                    <div className='flex flex-col justify-center pe-4'>
                        <h1 className='pb-2 font-bold text-c_info_banner_text text-5xl'>
                            {inView && <span className={`animate-[counter_2s_ease-out_forwards] tabular-nums before:content-[counter(num)] [counter-set:_num_var(--num-products)] `}>
                                <span class="sr-only">{infoBannerItems[2].title}</span>
                                +
                            </span>}
                        </h1>
                        <p className=' text-lg font-bold text-white md:text-4xl lg:text-4xl'>
                            {infoBannerItems[2].description}
                        </p>
                    </div>
                    <div className='overflow-hidden flex flex-col h-full w-fit justify-center'>
                        <img
                            className='object-contain h-20'
                            src={infoBannerItems[2].image}
                            alt=''
                        >
                        </img>
                    </div>
                </div>
                <div className={`min-h-fit flex flex-row justify-center py-9 px-4`} >
                    <div className='flex flex-col justify-center pe-4'>
                        <h1 className='pb-2 font-bold text-c_info_banner_text text-5xl'>
                            {inView && <span className={`animate-[counter_2s_ease-out_forwards] tabular-nums before:content-[counter(num)] [counter-set:_num_var(--num-workers)]`}>
                                <span class="sr-only">{infoBannerItems[3].title}</span>
                                +
                            </span>}
                        </h1>
                        <p className=' text-lg font-bold text-white md:text-4xl lg:text-4xl'>
                            {infoBannerItems[3].description}
                        </p>
                    </div>
                    <div className='overflow-hidden flex flex-col h-full w-fit justify-center'>
                        <img
                            className='object-contain h-20'
                            src={infoBannerItems[3].image}
                            alt=''
                        >
                        </img>
                    </div>
                </div>
            {/* )} */}
        </div>
    )
}
export default NumberInfoBanner;
