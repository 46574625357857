import AnimatedComponent from "./AnimatedComponent"

export default function SocialMediaBanner() {
  return (
    <div className='py-6 flex flex-row max-w-4xl w-full h-fit' >
      <AnimatedComponent direction={`animate-fade-up animate-duration-500`}
       className='flex flex-col h-fit w-full items-center px-2'
       >
          <a className='h-fit w-fit'
             href="#!"
          >
              <svg xmlns="http://www.w3.org/2000/svg"
                  className='h-12 w-12 stroke-0 fill-white hover:fill-bg_secondary transition-colors duration-300 ease-in-out'
                  viewBox="0 0 22.81 49.41"
              >
                  <g>
                      <path d="M5.18,49.41h9.95v-24.92h6.94l.74-8.34h-7.68v-4.75c0-1.97.4-2.74,2.3-2.74h5.38V0h-6.89C8.51,0,5.18,3.26,5.18,9.5v6.65H0v8.45h5.18v24.81Z"/>
                  </g>
              </svg>
          </a>
      </AnimatedComponent>
      <AnimatedComponent direction={`animate-fade-up animate-duration-500`}
      className='flex flex-col h-fit max-w-40 w-full items-center px-2'>
          <a className='h-fit w-fit'
             href="https://www.instagram.com/bulutmarketcomtr/"
          >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className='h-12 stroke-0 fill-white hover:fill-bg_secondary transition-colors duration-300 ease-in-out'
                  viewBox="0 0 44.82 44.82"
              >
                  <g>
                      <path d="M22.41,4.04c5.98,0,6.69.02,9.06.13,2.18.1,3.37.46,4.16.77,1.05.41,1.79.89,2.58,1.68.78.78,1.27,1.53,1.68,2.58.31.79.67,1.98.77,4.16.11,2.36.13,3.07.13,9.06s-.02,6.69-.13,9.06c-.1,2.19-.46,3.37-.77,4.16-.41,1.05-.89,1.79-1.68,2.58-.78.78-1.53,1.27-2.58,1.68-.79.31-1.98.67-4.16.77-2.36.11-3.07.13-9.06.13s-6.69-.02-9.06-.13c-2.18-.1-3.37-.46-4.16-.77-1.05-.41-1.79-.89-2.58-1.68-.78-.78-1.27-1.53-1.68-2.58-.31-.79-.67-1.98-.77-4.16-.11-2.36-.13-3.07-.13-9.06s.02-6.69.13-9.06c.1-2.19.47-3.37.77-4.16.41-1.05.89-1.79,1.68-2.58.78-.78,1.53-1.27,2.58-1.68.79-.31,1.98-.67,4.16-.77,2.36-.11,3.07-.13,9.06-.13M22.41,0c-6.09,0-6.85.03-9.24.13-2.39.11-4.01.49-5.44,1.04-1.47.57-2.72,1.34-3.97,2.59-1.25,1.25-2.01,2.5-2.58,3.97-.55,1.43-.93,3.05-1.04,5.44-.11,2.39-.13,3.15-.13,9.24s.03,6.85.13,9.24c.11,2.39.49,4.01,1.04,5.44.57,1.47,1.34,2.72,2.58,3.97,1.25,1.25,2.5,2.01,3.97,2.59,1.43.55,3.05.93,5.44,1.04,2.39.11,3.15.13,9.24.13s6.85-.03,9.24-.13c2.39-.11,4.01-.49,5.44-1.04,1.47-.57,2.72-1.34,3.97-2.59,1.25-1.25,2.01-2.5,2.59-3.97.55-1.43.93-3.05,1.04-5.44.11-2.39.13-3.15.13-9.24s-.03-6.85-.13-9.24c-.11-2.39-.49-4.01-1.04-5.44-.57-1.47-1.34-2.72-2.59-3.97-1.25-1.25-2.5-2.01-3.97-2.59-1.43-.55-3.05-.93-5.44-1.04-2.39-.11-3.15-.13-9.24-.13"/>
                      <path d="M22.41,10.9c-6.36,0-11.51,5.15-11.51,11.51s5.15,11.51,11.51,11.51,11.51-5.15,11.51-11.51-5.15-11.51-11.51-11.51M22.41,29.88c-4.13,0-7.47-3.34-7.47-7.47s3.34-7.47,7.47-7.47,7.47,3.34,7.47,7.47-3.34,7.47-7.47,7.47"/>
                      <path d="M37.06,10.45c0,1.49-1.2,2.69-2.69,2.69s-2.69-1.2-2.69-2.69,1.2-2.69,2.69-2.69,2.69,1.2,2.69,2.69"/>
                  </g>
              </svg>
          </a>
      </AnimatedComponent>
      <AnimatedComponent direction={`animate-fade-up animate-duration-500`}
       className='flex flex-col h-fit max-w-40 w-full items-center px-2'>
          <a className='h-fit w-fit'
             href="https://twitter.com/bulutmarketcmtr"
          >
              <svg xmlns="http://www.w3.org/2000/svg"
                  className='h-12 stroke-0 fill-white hover:fill-bg_secondary transition-colors duration-300 ease-in-out'
                  viewBox="0 0 47.58 42.97">
                  <g>
                      <path d="M37.59,0h7.29l-16.01,18.23,18.71,24.73h-14.68l-11.49-15.03-13.16,15.03H.95l16.96-19.5L0,0h15.05l10.38,13.73L37.59,0ZM35.04,38.69h4.04L12.92,4.12h-4.34l26.46,34.56Z"/>
                  </g>
              </svg>
          </a>
      </AnimatedComponent>
      <AnimatedComponent direction={`animate-fade-up animate-duration-500`}
      className='flex flex-col h-fit max-w-40 w-full items-center px-2'>
          <a className='h-fit w-fit'
             href="https://www.linkedin.com/company/bulutmarket"
          >
              <svg xmlns="http://www.w3.org/2000/svg"
                  className='h-12 stroke-0 fill-white hover:fill-bg_secondary transition-colors duration-300 ease-in-out'
                  viewBox="0 0 40.97 40.99"
              >
                  <g>
                      <rect x=".81" y="13.66" width="8.46" height="27.33"/>
                      <path d="M5,10.09c2.76,0,5-2.26,5-5.04S7.77,0,5,0,0,2.26,0,5.04s2.24,5.04,5,5.04Z"/>
                      <path d="M22.79,26.65c0-3.84,1.77-6.13,5.15-6.13,3.11,0,4.61,2.2,4.61,6.13v14.35h8.42v-17.3c0-7.32-4.15-10.86-9.94-10.86s-8.24,4.52-8.24,4.52v-3.68h-8.11v27.33h8.11v-14.35Z"/>
                  </g>
              </svg>
          </a>
      </AnimatedComponent>
      <AnimatedComponent direction={`animate-fade-up animate-duration-500`}
       className='flex flex-col h-fit max-w-40 w-full items-center px-2'>
          <a className='h-fit w-fit'
              href="#!"
          >
              <svg xmlns="http://www.w3.org/2000/svg"
                  className='h-12 stroke-0 fill-white hover:fill-bg_secondary transition-colors duration-300 ease-in-out'
                  viewBox="0 0 65.42 46.09"
              >
                  <g>
                      <path d="M64.06,7.2c-.75-2.83-2.97-5.06-5.78-5.82-5.1-1.38-25.56-1.38-25.56-1.38,0,0-20.46,0-25.56,1.38-2.81.76-5.03,2.99-5.78,5.82-1.37,5.13-1.37,15.85-1.37,15.85,0,0,0,10.71,1.37,15.85.75,2.83,2.97,5.06,5.78,5.82,5.1,1.38,25.56,1.38,25.56,1.38,0,0,20.46,0,25.56-1.38,2.81-.76,5.03-2.99,5.78-5.82,1.37-5.14,1.37-15.85,1.37-15.85,0,0,0-10.71-1.37-15.85ZM26.02,32.77V13.32l17.1,9.73-17.1,9.73Z"/>
                  </g>
              </svg>
          </a>
      </AnimatedComponent>
    </div>
  )
};