
import React, { useEffect, useRef, useState } from 'react';

const AnimatedComponent = ({ children, className, direction='animate-fade-left' }) => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      },
      {
        threshold: 0.3,
      }
    );
    
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      // className={`${inView ? direction : 'opacity-0'}  ${className}` }
      className={`${inView ? direction + ' animate-once animate-ease-in-out' : 'opacity-0'}  ${className}` }

    >
      {children}
    </div>
  );
};

export default AnimatedComponent;
