
function SvgComponent({ className }) {
  return (
    <>
      <img
        className={`inline dark:hidden  h-25 ` + className}
        src='/images/logo.svg'
      />
      <img
        className={`hidden dark:inline  h-25 ` + className}
        src='/logodark.svg'
      />
    </>
  )
}

export default SvgComponent
