import { Fragment, useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { PatternFormat } from 'react-number-format';
import Layout from '../Layout';
import AnimatedComponent from '../Components/AnimatedComponent'
import Background from '../public/images/bg_main.svg';
import XMLParser from 'react-xml-parser';
import SocialMediaBanner from '../Components/SocialMediaBanner';
import NavigationBar from '../Components/NavigationBar';

import handleTransformLang from '../functions/handleTransformLang'
import NavLang from '../lang/pages/nav.json'
import contact from '../lang/pages/contact.json'
import bankAccountsXml from 'bankalar-xml-file';

const department = [
	{ id: 1, name: 'Yönetim' },
	{ id: 2, name: 'Satış/İş Geliştirme' },
	{ id: 3, name: 'Pazarlama' },
	{ id: 4, name: 'Teknik Servis' },
	{ id: 5, name: 'Bulutmarket Destek' },
	{ id: 6, name: 'Finans/Muhasebe' },
	{ id: 7, name: 'Lojistik/Depo' },
]

export default function Contact() {
	return (
		<Layout headData={contact.head}>
			<InnerPage />
		</Layout>
	)
}

function InnerPage() {

	function getIterableData(data) {
		return Object.values(data).map(
			(value) => handleTransformLang(value)
		)
	}

	function getXmlData(file) {
		fetch(file)
			.then((res) => res.text())
			.then(xmlString => {
				var data = new XMLParser().parseFromString(xmlString, "text/xml")
				setBankAccounts(data)
			})
			//.then(data => console.log(data))
			.catch((err) => {
				console.log(err)
				setBankAccounts(null)
			});
	}

	const { language } = useContext(LanguageContext);

	const [content, setContent] = useState(handleTransformLang(NavLang));
	const [mainSection, setMainSection] = useState(handleTransformLang(contact).mainSection)
	const [addressSection, setAddressSection] = useState(handleTransformLang(contact).addressSection)
	const [bankAccounts, setBankAccounts] = useState(null)

	useEffect(() => {
		setContent(handleTransformLang(NavLang))
		setMainSection(handleTransformLang(contact).mainSection)
		setAddressSection(handleTransformLang(contact).addressSection)
		getXmlData(bankAccountsXml)
	}, [language])

	const [selected, setSelected] = useState(department[0])
	const [query, setQuery] = useState('')

	const filteredDepartment =
		query === ''
			? department
			: department.filter((department) => {
				return department.name.toLowerCase().includes(query.toLowerCase())
			})

	return (
		<>
			<section className="pb-10 bg-top bg-local bg-no-repeat bg-[length:1915px_750px]"
				style={{ backgroundImage: "url(" + Background + ")" }}
			>
				<div class="w-full h-1.5 bg-bg_secondary" />

				<div className="mx-auto py-11 px-4 max-w-8xl container ">
					<NavigationBar content={content} />
				</div>

				<section className='py-10 flex flex-col flex-grow items-center overflow-hidden max-h-full dark:bg-indigo-950'>
					<div className='px-4 max-w-8xl w-full'>
						<div className='w-full h-fit grid border-1 grid-cols-2 gap-16 lg:py-10 md:py-8 py-6 '>
							<AnimatedComponent
								direction={`animate-fade-right`}
								className={`w-full h-full flex flex-col justify-center lg:col-span-1 pe-4`} >
								<div className='flex flex-col justify-center h-fit w-full '>
									<h1 className='text-start mb-8 text-text_tertiary text-3xl font-semibold'>
										{mainSection.header1}
									</h1>
									<div className='flex xl:flex-row flex-col h-fit w-full mb-16 text-text_secondary md:text-lg lg:text-lg'>
										<p className='text-start text-lg xl:me-4'>
											{mainSection.text1_1}
										</p>
										<p className='text-start text-lg text-text_tertiary hidden xl:block'>
											|
										</p>
										<p className='text-start text-lg xl:mx-4'>
											{mainSection.text1_2}
										</p>
										<p className='text-start text-lg text-text_tertiary hidden xl:block'>
											|
										</p>
										<p className='text-start text-lg xl:ms-4'>
											{mainSection.text1_3}
										</p>
									</div>
								</div>

								<div className='flex flex-col justify-center h-fit w-full '>
									<h1 className=' text-start mb-8 text-text_tertiary text-3xl font-semibold'>
										{mainSection.header2}
									</h1>
									<p className='text-start text-lg mb-2.5 text-text_secondary md:text-lg lg:text-lg'>
										{mainSection.text2_1}
									</p>
									<div className='mb-8 flex md:flex-row flex-col h-fit w-full text-text_secondary md:text-lg lg:text-lg'>
										<p className='text-start text-lg md:me-4'>
											{mainSection.text2_2}
										</p>
										<p className='text-start text-lg text-text_tertiary hidden md:block'>
											|
										</p>
										<p className='text-start text-lg md:ms-4'>
											{mainSection.text2_3}
										</p>
									</div>
								</div>
							</AnimatedComponent>

							<AnimatedComponent
								direction={`animate-fade-left`}
								className={`w-full h-full lg:col-span-1`} >
								<div className='overflow-hidden flex justify-center'>
									<img
										className='object-contain h-125 xl:me-16'
										src={mainSection.image}
										alt=''
									>
									</img>
								</div>
							</AnimatedComponent>
						</div>
					</div>
				</section>
			</section>

			<section className='pt-10 pb-20 flex flex-col items-center justify-center  max-h-full dark:bg-indigo-950'>
				<div className=' max-w-8xl px-4 w-full py-10'>
					<div className='grid grid-cols-2 justify-between'>
						{Object.values(addressSection)?.map((addressItem, index) =>
							<AnimatedComponent direction={`animate-fade-up animate-duration-500`}
								className='flex flex-col w-full h-fit lg:[&:nth-child(odd)]:pe-30 lg:[&:nth-child(even)]:ps-30 pb-10 pt-10 [&:nth-child(n+3)]:border-t-2 [&:nth-child(odd)]:border-e-2 [&:nth-child(odd)]:pe-4 [&:nth-child(even)]:ps-4' >
								<h1 className='pb-3 text-start text-text_tertiary text-3xl font-semibold '>
									{addressItem.title}
								</h1>
								<p className='pb-1 text-lg text-text_secondary md:text-lg lg:text-lg '>
									{addressItem.telephone}
								</p>
								<p className='pb-3 text-lg text-text_secondary md:text-lg lg:text-lg '>
									{addressItem.address}
								</p>
								<div className='flex flex-row w-full h-fit'>
									<img
										className='object-contain max-h-8 pe-2'
										src='/images/harita_icon.svg'
										alt=''
									/>
									<a target="_blank"
										rel="noopener noreferrer"
										href={addressItem.location}
										className='text-lg w-fit text-text_primary font-semibold hover:text-text_tertiary decoration-text_tertiary
                               underline underline-offset-4 justify-center md:text-lg lg:text-lg transition-colors duration-200 ease-in-out'
									>
										Haritada Göster
									</a>
								</div>
								<div>
								</div>
							</AnimatedComponent>
						)}
					</div>
				</div>
			</section>

			<section id="bank" className='pt-10 pb-20 flex flex-col items-center justify-center  max-h-full bg-bg_tertiary dark:bg-indigo-950'>
				<div className=' max-w-8xl px-4 w-full py-10'>
					<div className='w-full flex flex-col mb-15 text-start'>
						<h1 className='font-bold text-text_primary text-4xl '>
							Bulutmarket Banka Hesap Numaraları
						</h1>
					</div>

					<table class="table-auto w-full text-lg">
						<thead class="border-b-2 border-text_tertiary text-text_tertiary ">
							<tr>
								<th class="text-start py-3 pe-3">BANKA ADI</th>
								<th class="text-start py-3 pe-3">HESAP TİPİ</th>
								<th class="text-start py-3">IBAN NUMARASI</th>
							</tr>
						</thead>
						<tbody class="text-text_secondary">
							{(bankAccounts && bankAccounts.children) &&
								Object.values(bankAccounts?.children)?.map((bankAccount, index) =>
									<tr class="border-b border-text_secondary">
										<td class="py-3 pe-3">{bankAccount?.children[0]?.value}</td>
										<td class="py-3 pe-3">{bankAccount?.children[1]?.value}</td>
										<td class="py-3">{bankAccount?.children[3]?.value}</td>
									</tr>
								)}
						</tbody>
					</table>
				</div>
			</section>

			<section className='flex flex-col items-center justify-center text-center bg-bg_primary max-h-full dark:bg-indigo-950'>
				<div className=' max-w-8xl w-full h-full px-4 flex flex-col items-center justify-center'>
					<SocialMediaBanner />
				</div>
			</section>

			<section className="pt-10 pb-20 flex flex-col items-center justify-center  max-h-full bg-white dark:bg-indigo-950">
				<div className=' max-w-8xl px-4 w-full py-10'>

					<div className='w-full flex flex-col mb-15 text-center'>
						<h1 className='font-bold text-text_primary text-4xl '>
							Bizimle İletişime Geçin
						</h1>
					</div>
					<form className="flex flex-col w-full xl:pe-25 items-center justify-center">
						<div className="grid grid-cols-2 max-w-7xl items-center justify-center w-full mt-4">
							<div className='mx-4 flex flex-row justify-center items-center'>
								<label
									className="pe-3 w-full basis-1/3 text-text_secondary text-lg text-end font-bold"
									htmlFor="text"
								>
									Şirket Adı

								</label>
								<input
									id="company"
									type="text"
									maxlength="50"
									class="block w-full basis-2/3 border-0 py-3 px-2 focus:outline-none text-lg text-text_secondary ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-text_primary focus:ring-2"
								/>
							</div>
							<div className='mx-4 flex flex-row justify-center items-center'>
								<label
									className="pe-3 w-full basis-1/3 text-text_secondary text-lg text-end font-bold"
									htmlFor="text"
								>
									Adınız, Soyadınız

								</label>
								<input
									id="name"
									type="text"
									maxlength="50"
									class="block w-full basis-2/3 border-0 py-3 px-2 focus:outline-none text-lg text-text_secondary ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-text_primary focus:ring-2"
								/>
							</div>
							<div className='mx-4 mt-16 flex flex-row justify-center items-center'>
								<label
									className="pe-3 w-full basis-1/3 text-text_secondary text-lg text-end font-bold"
									htmlFor="email"
								>
									E-posta

								</label>
								<input
									id="email"
									type="email"
									maxlength="50"
									class="block w-full basis-2/3 border-0 py-3 px-2 focus:outline-none text-lg text-text_secondary ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-text_primary focus:ring-2"
								/>
							</div>
							<div className='mx-4 mt-16 flex flex-row justify-center items-center'>
								<label
									className="pe-3 w-full basis-1/3 text-text_secondary text-lg text-end font-bold"
									htmlFor="tel"
								>
									Telefon Numaranız

								</label>

								<PatternFormat format="+90 (###) ### ####"
									allowEmptyFormatting mask="_"
									class="block w-full basis-2/3 border-0 py-3 px-2 focus:outline-none text-lg text-text_secondary ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-text_primary focus:ring-2"
								/>


								{/* <input
								id="tel"
								type="tel"
								pattern="[0-9]*"
								maxlength="11"
								class="block w-full basis-2/3 border-0 py-3 px-2 focus:outline-none text-lg text-text_secondary ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-text_primary focus:ring-2" 
								/> */}
							</div>

							<div className='mx-4 mt-16 flex flex-row justify-center items-center'>
								<label
									className="pe-3 w-full basis-1/3 text-text_secondary text-lg text-end font-bold"
									htmlFor="email"
								>
									İlgili Birim

								</label>

								<div className="w-full basis-2/3" >
									<Combobox value={selected} onChange={setSelected}>
										<div className="relative">
											<div className="relative w-full cursor-default border-0">
												<Combobox.Input
													className="w-full border-none pe-8 text-lg py-3 ps-2 ring-1 ring-gray-400 text-text_secondary focus:outline-none  focus:ring-text_primary focus:ring-2"
													displayValue={(person) => person.name}
													onChange={(event) => setQuery(event.target.value)}
												/>
												<Combobox.Button className="absolute inset-y-0 end-0 flex items-center pe-1">
													<ChevronUpDownIcon
														className="h-7 w-7 text-gray-400"
														aria-hidden="true"
													/>
												</Combobox.Button>
											</div>
											<Transition
												as={Fragment}
												leave="transition ease-in duration-200"
												leaveFrom="opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
												enter="transition ease-out duration-200"
												enterFrom="opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												afterLeave={() => setQuery('')}
											>
												<Combobox.Options className="absolute mt-2 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-gray-400 ring-1 text-text_secondary focus:outline-none">
													{filteredDepartment.length === 0 && query !== '' ? (
														<div className="relative cursor-default select-none px-4 py-2 text-gray-400">
															İlgili birim bulunamadı
														</div>
													) : (
														filteredDepartment.map((person) => (
															<Combobox.Option
																key={person.id}
																className={({ active }) =>
																	`relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-bg_primary text-white' : 'text-text_secondary'}`
																}
																value={person}
															>
																{({ selected, active }) => (
																	<>
																		<span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
																			{person.name}
																		</span>
																		{selected ? (
																			<span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-text_tertiary'}`}>
																				<CheckIcon className="h-5 w-5" aria-hidden="true" />
																			</span>
																		) : null}
																	</>
																)}
															</Combobox.Option>
														))
													)}
												</Combobox.Options>
											</Transition>
										</div>
									</Combobox>
								</div>
							</div>

							<div className='me-4 ms-2.25 mt-16 flex flex-row justify-end items-end col-span-2'>
								<textarea
									id="message"
									maxlength="500"
									class="block w-full h-52 basis-5/6 border-0 py-4 px-3 focus:outline-none text-lg text-text_secondary ring-1 ring-gray-400 placeholder:text-gray-400 focus:ring-text_primary focus:ring-2"
									placeholder="Lütfen iletişime geçmek istediğiniz konuyu kısaca yazınız"
								/>
							</div>


						</div>
						<div className="flex flex-col max-w-7xl w-full justify-end items-end mt-15 me-8">
							<input
								type='submit'
								value="GÖNDER"
								className='transition-all duration-500 h-fit w-fit rounded-full px-10 py-3 bg-gradient-to-r
                           from-button_transition_1 via-button_transition_2 to-button_transition_1 text-white
                           font-bold text-lg bg-size-200 bg-pos-0 hover:bg-pos-100 cursor-pointer'
							/>
						</div>
					</form>
				</div>
			</section>
		</>
	)
}
