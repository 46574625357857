import { HashLink } from 'react-router-hash-link';

export default function FooterSectorLink({ className = '', children, to, ...props }) {
  return (
    <>
      <HashLink
        {...props}
        smooth
        to={to}
        className="group w-fit text-lg text-white dark:text-gray-400 transition duration-300"
      >
          {children}
          <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white"></span>
      </HashLink>
    </>
  );
}
