import AnimatedComponent from '../Components/AnimatedComponent'

export default function MainSection({ title, description ,src}) {

    return (
        <>
            <div className='grid border-1 gap-12 lg:gap-16 grid-cols-2 lg:py-10 md:py-8 py-6 '>

                <AnimatedComponent
                    direction={`animate-slide-left`}
                    className={`w-full h-full lg:col-span-1 pe-20`} >
                    <div className='flex flex-col justify-center h-full w-full '>
                        <h1 className=' text-start font-bold mb-6 text-text_primary text-2xl md:text-4xl lg:text-4xl '>
                            {title}
                        </h1>
                        <p className='text-start text-lg mb-7 text-text_secondary md:text-lg lg:text-lg'>
                            {description}
                        </p>
                        <button
                            className='transition-all duration-500 h-fit w-fit rounded-full px-6 py-2 bg-gradient-to-r
                                from-button_transition_1 via-button_transition_2 to-button_transition_1 text-white
                                font-bold text-lg bg-size-200 bg-pos-0 hover:bg-pos-100'
                        >
                            DEVAMINI OKU
                        </button>
                    </div>
                </AnimatedComponent>

                <AnimatedComponent
                    direction={`animate-slide-right`}
                    className={`w-full h-full lg:col-span-1`} >
                    <div className='overflow-hidden'>
                        <div className='overflow-hidden  flex justify-center lg:justify-end'>
                            <img
                                className='object-contain h-full'
                                src={src}
                            >
                            </img>
                        </div>
                    </div>
                </AnimatedComponent>

            </div>
        </>
    )
}
