import { NavLink } from 'react-router-dom';
export default function NavbarLink({ active = false, className = '', children, to, ...props }) {
    return (
        <>
            <NavLink
                {...props}
                to={to}
                className={({ isActive }) =>
                    isActive
                        ? 'pb-1 text-text_primary hover:text-bg_quaternary text-lg font-bold transition-colors duration-200 ease-in-out'
                        : 'pb-1 text-white hover:text-gray-300 text-lg transition-colors duration-200 ease-in-out'
                }
            >
                {children}
            </NavLink>
        </>
    );
}
