import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { LanguageContext } from './Components/LanguageContext.jsx';
import React from 'react'
import { useLocation } from 'react-router-dom';
import NavLang from './lang/pages/nav.json'
import handleTransformLang from './functions/handleTransformLang.js'
import handleLangSwitch from './functions/handleLangSwitch.js'
import ProductsData from './lang/products.json'
import FooterBackground from './public/images/bg_footer.svg';
import FooterSectorLink from './Components/FooterSectorLink.jsx';

export default function Layout({ children, headData = '' }) {

    let pathname = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function getIterableData() {
        return Object.values(ProductsData).map(
            (product) => handleTransformLang(product)
        )
    }

    const [language, setLanguage] = useState(handleLangSwitch(localStorage.getItem('selectedLanguage')));
    const [content, setContent] = useState(handleTransformLang(NavLang));
    const [products, setProducts] = useState(getIterableData());

    useEffect(() => {
        setContent(handleTransformLang(NavLang))
        setProducts(getIterableData())
        handleMetaTitle(headData)
    }, [language])
    return (
        <LanguageContext.Provider value={{ language, setLanguage, handleLangSwitch }}>
            {/* <Nav /> */}
            {/* <div class="absolute top-0 right-0">
                <img
                    className={'h-[46rem]'}
                    src='/images/diagonal.svg'
                />
            </div> */}
            {/* <main class="border-0 bg-white bg-right-top bg-local bg-no-repeat bg-[length:58%]"
                 style={{backgroundImage: "url(" + Background + ")"}}>
                {children}
            </main> */}
            <main class="border-0 bg-white">
                {children}
            </main>


            <footer className="bg-bg_secondary bg-top bg-local bg-no-repeat bg-[length:1915px_396px]"
                style={{ backgroundImage: "url(" + FooterBackground + ")" }}
            >
                <div className="container max-w-8xl px-4 pt-24 pb-9 mx-auto flex flex-row">
                    <div className="flex shrink-0 items-center">
                        <Link to='/' className='justify-center'>
                            <img
                                className={`inline dark:hidden justify-center h-full w-76 fill-current text-gray-800 dark:text-gray-200`}
                                src='/images/footer_logo.svg'
                                alt=''
                            />
                        </Link>
                    </div>
                    
                    <div className="flex flex-1 justify-end">
                        <div className="flex flex-row pt-2">
                            {Object.values(content)?.map((contentItem, index) =>
                                contentItem != null && contentItem.name && contentItem.name !== "İLETİŞİM" && (
                                    <div className='px-7 [&:nth-last-child(1)]:pe-0'>
                                        <Link to={contentItem.to} className=" text-text_tertiary font-bold text-lg dark:text-white">
                                            {contentItem.name}
                                        </Link>

                                        <div className='flex flex-col'>
                                            {contentItem.sectorLinks && Object.values(contentItem.sectorLinks)?.map((sectorLink, index) =>

                                                <FooterSectorLink to={sectorLink.to}>
                                                    {sectorLink.name}
                                                </FooterSectorLink>
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    {/* <hr className="h-px my-6 border-[1px] border-black/10 dark:border-gray-800" /> */}
                    {/* <a href="mailto:bilgi@bilnes.com" className="block mt-2 text-lg text-white dark:text-gray-400 hover:underline">bilgi@bilnes.com</a> */}
                </div>

                <div className="container max-w-8xl w-fit py-10 mx-auto flex flex-row divide-x-2 divide-text_tertiary text-center">
                    <Link to='/' className="px-4 text-text_tertiary font-bold text-lg dark:text-white">
                        Gizlilik Politikası
                    </Link>
                    <Link to='/' className="px-4 text-text_tertiary font-bold text-lg dark:text-white">
                        Çerez Politikası
                    </Link>
                    <Link to='/' className="px-4 text-text_tertiary font-bold text-lg dark:text-white">
                        Kişisel Veri Politikası
                    </Link>
                    <Link to='/' className="px-4 text-text_tertiary font-bold text-lg dark:text-white">
                        Kişisel Verileri Aydınlatma Metni
                    </Link>
                </div>

                <div className='flex w-full h-11 justify-center items-center text-center px-4 bg-bg_primary'>
                    <p className="w-full max-w-8xl text-lg tracking-wide text-white">
                        BULUTMARKET BİLİŞİM VE GÜVENLİK SİSTEMLERİ A.Ş. | Her Hakkı Saklıdır. @2024 
                    </p>
                </div>
            </footer>
        </LanguageContext.Provider>
    )
}

function handleMetaTitle(headData) {
    const translatedData = handleTransformLang(headData);

    const titleElement = document.head.querySelector(`title`);
    if (titleElement) {
        if (translatedData.title) {
            titleElement.innerHTML = translatedData.title;
        }
    } else {
        if (translatedData.title) {
            const newTitle = document.createElement("title");
            newTitle.innerHTML= translatedData.title;
            document.head.appendChild(newTitle);
        }
    }

    const metaElement = document.head.querySelector(`meta[name="description"]`);
    if (metaElement) {
        metaElement.setAttribute("content", translatedData.description);
    } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", translatedData.description);
        document.head.appendChild(newMeta);
    }
}
