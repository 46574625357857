import { Link } from 'react-router-dom'
import Logo from '../Components/Logo';
import NavbarLink from '../Components/Navbarlink';
import LangSwitcher from '../Components/LangSwitcher';


export default function NavigationBar({ content }) {
  return (
    <div className="flex flex-1 justify-between ">
      <div className="relative shrink-0 flex items-center" >
        <Link to='/' className=''>
          <Logo className=" w-auto fill-current text-gray-800 dark:text-gray-200" />
        </Link>
        {/* dark:invert dark:hue-rotate-[210deg] block */}
      </div>
      <div className='flex items-center mt-8'>
        <ul className='flex justify-end my-6 font-light md:flex '>
          <li className='first:ml-1 mx-6 last:mr-1'>
            <NavbarLink to={'/corporate'} >
              {content.corporate.name}
            </NavbarLink>
          </li>
          <li className='mx-6 last:mr-1'>
            <NavbarLink to={'/support'} >
              {content.support.name}
            </NavbarLink>
          </li>
          <li className='mx-6 last:mr-1'>
            <NavbarLink to={'/dealership'} >
              {content.dealership.name}
            </NavbarLink>
          </li>
          <li className='mx-6 '>
            <NavbarLink to={'/contact'} >
              {content.contact.name}
            </NavbarLink>
          </li>
        </ul>
        <div className='mx-2 flex justify-center items-center'>
          <a href="https://b2b.bulutmarket.com.tr"
              className="rounded-full px-5 py-2 bg-bg_secondary border border-white text-white text-lg
              font-semibold hover:bg-bg_quaternary transition-colors duration-200 ease-in-out"
          >
              B2B GİRİŞİ
          </a>
          {/* <button className="rounded-full px-5 py-2 bg-bg_secondary border border-white text-white text-lg
                             font-semibold hover:bg-bg_quaternary transition-colors duration-200 ease-in-out"
          >
            B2B GİRİŞİ
          </button> */}
        </div>
        <div className='ms-6 flex justify-center items-center'>
          <LangSwitcher />
        </div>
      </div>
    </div>
  )
}