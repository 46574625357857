import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import AnimatedComponent from "./AnimatedComponent";
import productRangeData from '../lang/productRange.json'
import handleTransformLang from '../functions/handleTransformLang'

const ProductRange = () => {

  const { language } = useContext(LanguageContext);
  const [productRanges, setProductRanges] = useState(getIterableData(productRangeData));

  function getIterableData(data) {
    return Object.values(data).map(
        (product) => handleTransformLang(product)
    )
  }

  useEffect(() => {
      setProductRanges(getIterableData(productRangeData))
  }, [language])

  return (
    <div className=' max-w-8xl px-4 h-fit grid grid-cols-4 py-11 divide-gray-300'>
      {productRanges?.map((productRangeItem, index) =>
          <AnimatedComponent
              direction={`animate-fade-up`}
              className={`min-h-fit flex flex-col justify-start px-7 [&:nth-child(4n+1)]:border-s-0 border-s [&:nth-child(n+5)]:mt-20`} >
              <div className='pb-2 flex justify-start items-start'>
                  <img
                      className='object-contain max-h-12'
                      src={productRangeItem.image}
                      alt=''
                  >
                  </img>
              </div>
              <div className='flex flex-col justify-start whitespace-pre-line'>
                  <h1 className='pb-3 font-bold text-text_tertiary text-3xl'>
                      {productRangeItem.title}
                  </h1>
                  <p className='whitespace-pre-line text-lg text-text_secondary md:text-lg lg:text-lg'>
                      {productRangeItem.description}
                  </p>
              </div>
          </AnimatedComponent>
      )}
    </div>
  )
}
export default ProductRange;
