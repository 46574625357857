import Layout from '../Layout';
import { LanguageContext } from '../Components/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import handleTransformLang from '../functions/handleTransformLang'
import NavLang from '../lang/pages/nav.json'
import AnimatedComponent from '../Components/AnimatedComponent'
import Background from '../public/images/bg_main.svg';
import infoBannerData from '../lang/corporatePage/infoBanner.json'
import valuableServicesStartData from '../lang/corporatePage/valuableServicesStart.json'
import valuableServicesEndData from '../lang/corporatePage/valuableServicesEnd.json'
import NavigationBar from '../Components/NavigationBar';
import LangData from '../lang/pages/about.json'
import NumberInfoBanner from '../Components/NumberInfoBanner';
import ProductRange from '../Components/ProductRange';
import Brands from '../Components/Brands';

export default function Corporate() {
    return (
        <Layout headData={LangData.head}>
            <InnerPage />
        </Layout>
    )
}

function InnerPage() {
    function getIterableData(data) {
        return Object.values(data).map(
            (employee) => handleTransformLang(employee)
        )
    }

    const { language } = useContext(LanguageContext);
    const [content, setContent] = useState(handleTransformLang(NavLang));
    const [infoBannerItems, setInfoBannerItems] = useState(getIterableData(infoBannerData))
    const [valuableServicesStartItems, setValuableServicesStartItems] = useState(getIterableData(valuableServicesStartData))
    const [valuableServicesEndItems, setValuableServicesEndItems] = useState(getIterableData(valuableServicesEndData))


    
    useEffect(() => {
        setContent(handleTransformLang(NavLang))
        setInfoBannerItems(getIterableData(infoBannerData))
        setValuableServicesStartItems(getIterableData(valuableServicesStartData))
        setValuableServicesEndItems(getIterableData(valuableServicesEndData))
    }, [language])

    return (
        <>
            <section className="pb-10 bg-top bg-local bg-no-repeat bg-[length:1915px_750px]"
                style={{ backgroundImage: "url(" + Background + ")" }}
            >
                <div class="w-full h-1.5 bg-bg_secondary" />

                <div className="mx-auto py-11 px-4 max-w-8xl container ">
                    <NavigationBar content={content} />
                </div>

                <section id="about" className='py-10 flex flex-col flex-grow items-center overflow-hidden max-h-full dark:bg-indigo-950'>
                    <div className='px-4 max-w-8xl w-full'>
                        <div className='w-full h-fit grid border-1 grid-cols-2 gap-16 lg:py-10 md:py-8 py-6 '>
                            <AnimatedComponent
                                direction={`animate-slide-left`}
                                className={`w-full h-full flex flex-col justify-center lg:col-span-1 pe-4`} >
                                <div className='flex flex-col justify-center h-fit w-full '>
                                    <h1 className='text-start mb-8 text-text_primary text-4xl font-bold'>
                                    Hakkımızda
                                    </h1>
                                    <p className='text-start text-lg xl:me-4 text-text_secondary'>
                                    2024 yılı Şubat ayının son günlerinde teknoloji dünyasına “Merhaba” demiş
                                    yeni bir şirketiz. Amacımız Türkiye’de bilişim ve güvenlik sistemleri
                                    dağıtıcılığında lider olmak. Azim ve kararlılıkla çıktığımız bu yolda, henüz
                                    yolun başındayken sizlerle birlikte bu hedefi yakalayacağımıza inancımız tam.
                                    </p>
                                </div>
                            </AnimatedComponent>

                            <AnimatedComponent
                                direction={`animate-slide-right`}
                                className={`w-full h-full lg:col-span-1`} >
                                <div className='overflow-hidden flex justify-center'>
                                    <img
                                        className='object-contain h-125 xl:pe-16'
                                        src="images/corporate_banner.svg"
                                        alt=''
                                    >
                                    </img>
                                </div>
                            </AnimatedComponent>
                        </div>
                    </div>
                </section>
            </section>

            <section className='pt-10 pb-40 flex flex-col items-center justify-center  max-h-full dark:bg-indigo-950'>
                <div className='max-w-8xl px-4 w-full'>
                    <AnimatedComponent 
                    direction='animate-fade'
                    className="flex flex-row w-full">
                        <div className='overflow-hidden flex justify-center pe-10'>
                            <img
                                className='object-contain h-44 w-56'
                                src="images/corporateBanners/c_banner1.svg"
                                alt=''
                            >
                            </img>
                        </div>
                        <div className='flex flex-col justify-center w-full '>
                            <h1 className='text-start mb-6 text-text_primary text-4xl font-bold'>
                            Bilişim ve Güvenlik Sistemlerinde Yeni Adresiniz
                            </h1>
                            <p className='text-start text-lg xl:me-4 text-text_secondary'>
                            Türkiye’nin bilişim ve güvenlik sistemleri alanında lider firması olmak hedefiyle yola çıktık. Profesyonelliği samimiyetle harmanladığımız dürüst,
                            güvenilir, ilkeli ve saygılı bir ticaret hedefliyoruz. Tüm bilişim ve güvenlik sistemleri ihtiyaçlarınızı tek adresten temin edebileceğiniz geniş marka ve
                            ürün çeşitliliği ile hizmetinizdeyiz. Uzman kadromuzla, rekabetçi fiyatlarımızla, satış öncesi ve sonrası güçlü desteğimizle iş ortaklarımızı yarışa bir adım
                            öde başlatmayı hedefliyoruz.
                            </p>
                        </div>
                    </AnimatedComponent>

                    <AnimatedComponent 
                    direction='animate-fade'
                    className="flex flex-row w-full pt-52">
                        <div className='overflow-hidden flex justify-center pe-10'>
                            <img
                                className='object-contain h-44 w-56'
                                src="images/corporateBanners/c_banner2.svg"
                                alt=''
                            >
                            </img>
                        </div>
                        <div className='flex flex-col justify-center w-full '>
                            <h1 className='text-start mb-6 text-text_primary text-4xl font-bold'>
                            Güvenilir ve İtibarlı Bir Ticaret Hedefliyoruz
                            </h1>
                            <p className='text-start text-lg xl:me-4 text-text_secondary'>
                            Bir taraftan çalıştığımız kurumu sahiplenerek en iyi şekilde temsil etmeye çalışırken, diğer taraftan iş ortaklarımıza karşı saygılı, tutarlı, dürüst olmaya ve
                            verdiğimiz sözlerin arkasında durmaya özen gösteriyoruz.
                            </p>
                        </div>
                    </AnimatedComponent>
                    <AnimatedComponent 
                    direction='animate-fade'
                    className="flex flex-row w-full h-fit pt-52">
                        <div className='overflow-hidden flex flex-col h-fit w-fit justify-center pe-10'>
                            <img
                                className='object-contain h-44 w-56'
                                src="images/corporateBanners/c_banner3.svg"
                                alt=''
                            >
                            </img>
                        </div>
                        <div className='flex flex-col justify-center w-full '>
                            <h1 className='text-start mb-6 text-text_primary text-4xl font-bold'>
                            Liyakatlı Bir Kadro
                            </h1>
                            <p className='text-start text-lg xl:me-4 text-text_secondary'>
                            Bireysel yetkinliklerin takım ruhuyla bütünleştiği liyakatlı bir kadroyuz. Paydaşı olduğumuz bilişim sektörünün hızlı değişen yapısına uyumlu olarak,
                            sürekli kendimizi yenileyerek ve takım olarak birlikte öğrenerek yola devam etmek istiyoruz. Sonuç odaklıyız. İş ortaklarımıza hayatı ve ticareti
                            kolaylaştırmayı hedefliyoruz. 
                            </p>
                        </div>
                    </AnimatedComponent>
                </div>
            </section>

            <section className='flex flex-col items-center justify-center text-center bg-bg_secondary max-h-full'>
                <div className='max-w-8xl px-4 w-full h-fit'>
                    <NumberInfoBanner infoBannerItems={infoBannerItems} />
                </div>
            </section>

            <section id='press' className='flex flex-col items-center justify-center text-center bg-bg_primary max-h-full'>
                <div className='px-4 max-w-8xl w-full'>
                    <div className='w-full flex flex-col pt-10 text-start'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            Basın Kiti
                        </h1>
                    </div>
                </div>

                <div className=' max-w-8xl px-4 w-full grid grid-cols-2 gap-25 pt-10 pb-16'>
                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row w-full justify-start items-center pb-7'>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 26.3 23.2"
                                className='h-7 w-7 stroke-0 fill-bg_secondary me-2'
                            >
                                <g>
                                    <path class="cls-1" d="M10.3,14.23c.48-.8.91-1.6,1.41-2.36,1.61-2.47,3.62-4.58,5.86-6.48,2.4-2.04,5.01-3.76,7.78-5.25.25-.13.51-.23.73-.01.13.13.21.35.22.53,0,.13-.12.31-.24.41-3.15,2.67-5.78,5.79-8.02,9.24-2.46,3.79-4.3,7.85-5.41,12.24-.01.05-.03.09-.04.14-.05.25-.19.43-.44.49-.26.07-.45-.05-.62-.23-.78-.84-1.55-1.68-2.33-2.52C6.23,17.22,3.27,14.02.31,10.82c-.05-.05-.09-.1-.14-.15-.18-.19-.22-.41-.12-.65.1-.24.31-.35.56-.35.46,0,.92,0,1.38,0,1.3,0,2.6,0,3.9,0,.31,0,.54.1.74.34,1.13,1.31,2.27,2.61,3.4,3.92.09.1.18.2.27.3Z"/>
                                </g>
                            </svg>
                            <p className=' text-lg text-white'>
                                Bulutmarket logosunu indirmek için tıklayın.
                            </p>
                        </div>
                        <div className='flex flex-row w-full justify-start'>
                            <a href='downloadables/logo_pdf.zip'
                                className='me-8 transition-all duration-300 h-fit w-fit rounded-full px-8 py-2 bg-white text-text_tertiary
                                    font-bold text-lg hover:ring-2 hover:ring-bg_secondary'
                            >
                                PDF İNDİR
                            </a>
                            <a href='downloadables/logo_jpeg.zip'
                                className='me-8 transition-all duration-300 h-fit w-fit rounded-full px-8 py-2 bg-white text-text_tertiary
                                    font-bold text-lg hover:ring-2 hover:ring-bg_secondary'
                            >
                                JPEG İNDİR
                            </a>
                            <a href='downloadables/logo_svg.zip'
                                className='transition-all duration-300 h-fit w-fit rounded-full px-8 py-2 bg-white text-text_tertiary
                                    font-bold text-lg hover:ring-2 hover:ring-bg_secondary'
                            >
                                SVG İNDİR
                            </a>
                        </div> 
                    </div>
                    
                    <div className='flex flex-col w-full'>
                        <div className='flex flex-row w-full justify-start items-center pb-7'>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 26.3 23.2"
                                className='h-7 w-7 stroke-0 fill-bg_secondary me-2'
                            >
                                <g>
                                    <path class="cls-1" d="M10.3,14.23c.48-.8.91-1.6,1.41-2.36,1.61-2.47,3.62-4.58,5.86-6.48,2.4-2.04,5.01-3.76,7.78-5.25.25-.13.51-.23.73-.01.13.13.21.35.22.53,0,.13-.12.31-.24.41-3.15,2.67-5.78,5.79-8.02,9.24-2.46,3.79-4.3,7.85-5.41,12.24-.01.05-.03.09-.04.14-.05.25-.19.43-.44.49-.26.07-.45-.05-.62-.23-.78-.84-1.55-1.68-2.33-2.52C6.23,17.22,3.27,14.02.31,10.82c-.05-.05-.09-.1-.14-.15-.18-.19-.22-.41-.12-.65.1-.24.31-.35.56-.35.46,0,.92,0,1.38,0,1.3,0,2.6,0,3.9,0,.31,0,.54.1.74.34,1.13,1.31,2.27,2.61,3.4,3.92.09.1.18.2.27.3Z"/>
                                </g>
                            </svg>
                            <p className=' text-lg text-white'>
                                Bulutmarket Kurumsal Kimlik Klavuzunu görüntülemek için tıklayın.
                            </p>
                        </div>
                        <div className='flex flex-row w-full justify-start'>
                            <a href='downloadables/KURUMSAL_KIMLIK.pdf' target="_blank" rel="noopener noreferrer"
                                className='transition-colors duration-300 h-fit w-fit rounded-full px-8 py-2 bg-bg_secondary text-white
                                    font-bold text-lg hover:bg-bg_quaternary'
                            >
                                GÖRÜNTÜLE
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section id="vision" className='py-20 flex flex-col flex-grow items-center overflow-hidden max-h-full dark:bg-indigo-950'>
                <div className='px-4 max-w-8xl w-full'>
                    <div className='w-full h-fit grid border-1 grid-cols-2 gap-30 lg:py-10 md:py-8 py-6 '>
                        <AnimatedComponent
                            direction={`animate-fade-right`}
                            className={`w-full h-full lg:col-span-1`} >
                            <div className='overflow-hidden flex justify-center'>
                                <img
                                    className='object-contain h-125'
                                    src="images/vizyon_misyon.svg"
                                    alt=''
                                >
                                </img>
                            </div>
                        </AnimatedComponent>
                        <AnimatedComponent
                             direction={`animate-fade-left`}
                            className={`w-full h-full flex flex-col justify-center lg:col-span-1`} >
                            <div className='flex flex-col justify-center h-fit w-full xl:ps-4'>
                                <h1 className='text-start mb-6 text-text_primary text-4xl font-bold'>
                                Vizyonumuz
                                </h1>
                                <p className='text-start text-lg mb-16  text-text_secondary'>
                                Bilişim ve güvenlik sistemleri alanında, iş ortaklarımızın ihtiyaç duydukları ürünleri
                                sağlayarak Türkiye’nin lider dağıtıcısı olmaktır.
                                </p>
                                <h1 className='text-start mb-6 text-text_primary text-4xl font-bold'>
                                Misyonumuz
                                </h1>
                                <p className='text-start text-lg pb-8 text-text_secondary'>
                                Dağıtıcılığını yaptığımız marka ve ürünleri, katma değerli hizmetlerle Türkiye’nin her
                                noktasındaki iş ortaklarımıza sorunsuz ulaştırmaktır
                                </p>
                            </div>
                        </AnimatedComponent>

                    </div>
                </div>
            </section>

            <section className='py-10 flex flex-col flex-grow items-center overflow-hidden max-h-full dark:bg-indigo-950'>
                <div className='px-4 max-w-8xl w-full'>
                    <div className='w-full flex flex-col mb-15 text-start'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            Katma Değerli Hizmetlerimiz
                        </h1>
                    </div>
                    <div className='w-full h-fit grid border-1 grid-cols-2 gap-35 lg:py-10 md:py-8 py-6'>
                        <div className='grid grid-cols-1 w-full divide-y-2'>
                            {valuableServicesStartItems?.map((valuableServiceItem, index) =>
                                <AnimatedComponent direction='animate-fade-up'
                                 className='py-12 [&:nth-child(odd)]:pt-5'>
                                    <img
                                        className='object-contain pb-3 h-23'
                                        src={valuableServiceItem.image}
                                        alt=''
                                    >
                                    </img>
                                    <h1 className='pb-2 font-bold text-text_tertiary text-lg'>
                                        {valuableServiceItem.title}
                                    </h1>
                                    <p className='whitespace-pre-line text-text_secondary text-lg'>
                                        {valuableServiceItem.description}
                                    </p>
                                </AnimatedComponent>
                            )}
                        </div>
                        <div className='grid grid-cols-1 w-full divide-y-2'>
                            {valuableServicesEndItems?.map((valuableServiceItem, index) =>
                                <AnimatedComponent direction='animate-fade-up'
                                className='py-5'>
                                    <img
                                        className='object-contain pb-3 h-23'
                                        src={valuableServiceItem.image}
                                        alt=''
                                    >
                                    </img>
                                    <h1 className='pb-2 font-bold text-text_tertiary text-lg'>
                                        {valuableServiceItem.title}
                                    </h1>
                                    <p className='whitespace-pre-line text-text_secondary text-lg'>
                                        {valuableServiceItem.description}
                                    </p>
                                </AnimatedComponent>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section id="brands" className='pt-15 flex flex-col items-center justify-center bg-white max-h-full dark:bg-indigo-950'>
                <div className=' max-w-8xl pb-12 px-18 items-center flex flex-col '>
                    <div className='w-full flex flex-col text-center'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            Ürün Yelpazesi
                        </h1>
                    </div>
                </div>

                <ProductRange/>
            </section>

            <section className='pb-32 flex flex-col items-center justify-center bg-white max-h-full dark:bg-indigo-950'>
                <div className=' max-w-8xl py-12 px-18 items-center flex flex-col '>
                    <div className='w-full flex flex-col mt-15 text-center'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            Markalar
                        </h1>
                    </div>
                </div>

                <Brands/>
            </section>

        </>

    )
}
