import brandsData from '../lang/brands.json'

const Brands = () => {

    return (
      <div className="max-w-8xl px-4 h-fit grid grid-cols-6 py-11 ">
        {Object.values(brandsData).map((brand, index) =>
            <div className={`group min-h-fit w-full flex flex-col justify-center px-10 py-8 border-s
                [&:nth-child(6n+1)]:border-s-0 [&:nth-child(n+7)]:border-t
                [&:nth-child(n+7)]:py-12`}
            >
                <div className="relative w-40 h-26 flex flex-col">
                    <img className="absolute inset-0 group-hover:opacity-0 opacity-100 object-contain w-full h-full
                                    transition-opacity ease-in-out duration-300"
                        src={brand.image}
                        alt={brand.brandName}
                    />
                    <img className="absolute inset-0 group-hover:opacity-100 opacity-0 object-contain w-full h-full
                                    transition-opacity ease-in-out duration-300"
                        src={brand.hoverImage}
                        alt={brand.brandName}
                    />
                </div>
            </div>
        )}
      </div>
    )
}
export default Brands;
