import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Components/LanguageContext';

import Layout from '../Layout';
import handleTransformLang from '../functions/handleTransformLang'
import NavLang from '../lang/pages/nav.json'
import Background from '../public/images/bg_main.svg';
import contact from '../lang/pages/contact.json'
import NavigationBar from '../Components/NavigationBar';  


export default function Contact() {
    return (
        <Layout headData={contact.head}>
            <InnerPage />
        </Layout>
    )
}

function InnerPage() {
    const { language } = useContext(LanguageContext);

    const [content, setContent] = useState(handleTransformLang(NavLang));

    useEffect(() => {
        setContent(handleTransformLang(NavLang))
    }, [language])  

    return (
        <>
            <section className="pb-10 bg-top bg-local bg-no-repeat bg-[length:1915px_750px]"
                style={{ backgroundImage: "url(" + Background + ")" }}
            >
                <div class="w-full h-1.5 bg-bg_secondary" />

                <div className="mx-auto py-11 px-4 max-w-8xl container ">
                    <NavigationBar content={content} />
                </div>

                <section className='py-10 flex flex-col flex-grow items-center overflow-hidden max-h-full dark:bg-indigo-950'>
                    <div className='px-4 max-w-8xl w-full'>
                        <div className='w-full h-fit py-6 flex flex-col items-center'>
                                <div className='overflow-hidden flex justify-center mb-10'>
                                    <img
                                        className='object-contain w-200'
                                        src='images/openSoon/imaj.svg'
                                        alt=''
                                    >
                                    </img>
                                </div>
                                <div className='overflow-hidden flex justify-center'>
                                    <img
                                        className='object-contain w-125'
                                        src='images/openSoon/typo.png'
                                        alt=''
                                    >
                                    </img>
                                </div>
                                <div className='overflow-hidden flex justify-center'>
                                    <p className='text-xl text-text_primary'> Sizlere en iyi hizmeti vermek için çalışıyoruz.
                                    </p>
                                </div>
                        </div>
                    </div>
                </section>
            </section>
            <>
            </>
        </>

    )
}
