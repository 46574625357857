import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import Layout from '../Layout';
import MainSection from '../Partials/MainSection'
import NavLang from '../lang/pages/nav.json'
import AnimatedComponent from '../Components/AnimatedComponent'
import Background from '../public/images/bg_main.svg';
import NavigationBar from '../Components/NavigationBar';
import Brands from '../Components/Brands';
import ProductRange from '../Components/ProductRange';
import handleTransformLang from '../functions/handleTransformLang'

import LangData from '../lang/pages/home.json'
import homePageData from '../lang/homePage/homePage.json'
import orangeBannerData from '../lang/homePage/orangeBanner.json'
import blueBannerData from '../lang/homePage/blueBanner.json'
import whyBulutmarketData from '../lang/homePage/whyBulutmarket.json'

export default function Home() {
    return (
        <Layout headData={LangData.head}>
            <InnerPage />
        </Layout>
    )
}

function InnerPage() {

    const { language } = useContext(LanguageContext);
    const [main, setMain] = useState(handleTransformLang(LangData.main));
    const [content, setContent] = useState(handleTransformLang(NavLang));
    const [orangeBannerItems, setOrangeBannerItems] = useState(getIterableData(orangeBannerData));
    const [blueBannerItems, setBlueBannerItems] = useState(getIterableData(blueBannerData));
    const [whyBulutmarketItems, setWhyBulutmarketItems] = useState(getIterableData(whyBulutmarketData));
    const [section2, setSection2] = useState(handleTransformLang(homePageData.section2))
    const [section3, setSection3] = useState(handleTransformLang(homePageData.section3))
    const [section4, setSection4] = useState(handleTransformLang(homePageData.section4))

    function getIterableData(data) {
        return Object.values(data).map(
            (product) => handleTransformLang(product)
        )
    }

    useEffect(() => {
        setMain(handleTransformLang(LangData.main))
        setContent(handleTransformLang(NavLang))
        setOrangeBannerItems(getIterableData(orangeBannerData))
        setBlueBannerItems(getIterableData(blueBannerData))
        setWhyBulutmarketItems(getIterableData(whyBulutmarketData))
        setSection2(handleTransformLang(homePageData.section2))
        setSection3(handleTransformLang(homePageData.section3))
        setSection4(handleTransformLang(homePageData.section4))
    }, [language])

    return (
        <>
            <section className="pb-40 bg-top bg-local bg-no-repeat bg-[length:1915px_750px]"
                style={{ backgroundImage: "url(" + Background + ")" }}
            >
                <div class="w-full h-1.5 bg-bg_secondary" />

                <div className="mx-auto py-11 px-4 max-w-8xl container ">
                    <NavigationBar content={content}/>
                </div>

                <section className='py-10 flex flex-col items-center justify-center overflow-hidden max-h-full dark:bg-indigo-950'>
                    <div className='max-w-8xl px-4'>
                        <MainSection
                            title={main.title}
                            description={main.description}
                            src={main.src}
                        />
                    </div>
                </section>
            </section>


            <section className='flex flex-col items-center justify-center bg-bg_secondary max-h-full dark:bg-indigo-950'>
                <div className='container max-w-8xl px-4 h-fit grid gap-8 grid-cols-3 py-8'>
                    {blueBannerItems?.map((blueBannerItem, index) =>
                        <AnimatedComponent
                            direction={`animate-fade-up animate-duration-500`}
                            className={`min-h-fit flex flex-row`} >
                            <div className='basis-2/5 w-fit me-3 flex flex-col justify-center'>
                                <img
                                    className='object-contain h-30 w-30'
                                    src={blueBannerItem.image}
                                    alt=''
                                >
                                </img>
                            </div>
                            <div className='flex flex-col justify-center'>
                                <h1 className=' text-start font-bold text-text_quaternary text-lg md:text-lg lg:text-lg '>
                                    {blueBannerItem.title}
                                </h1>
                                <p className='text-start text-lg text-white md:text-lg lg:text-lg'>
                                    {blueBannerItem.description}
                                </p>
                            </div>
                        </AnimatedComponent>            
                    )}
                </div>
            </section>

            <section className='pt-32 pb-12 flex flex-col items-center justify-center bg-white max-h-full dark:bg-indigo-950'>
                <div className=' max-w-8xl py-4 px-18 items-center flex flex-col '>
                    <img
                        className='object-contain max-h-80'
                        src='/images/want_to_be_seller.png'
                        alt=''
                    >
                    </img>
                    <div className='w-full flex flex-col my-15 text-center'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            {section2.title}
                        </h1>
                        <p className='pt-3 xl:px-35 lg:px-26 md:px-0 text-lg text-text_secondary md:text-lg lg:text-lg '>
                            {section2.description}
                        </p>
                    </div>
                </div>

                <div className=' max-w-8xl w-fit h-fit grid gap-4 grid-cols-3 py-15 px-8'>
                    <div className='flex flex-col justify-center'>
                        <a className='h-fit w-fit'
                            href="#!">
                                <img
                                    className='object-contain max-h-74 w-fit transition-transform duration-300 transform hover:scale-105'
                                    src='/images/buton3.png'
                                    alt=''
                                />
                        </a>
                    </div>
                    <div className='flex flex-col justify-center'>
                        <a className='h-fit w-fit'
                            href="#!">
                                <img
                                    className='object-contain max-h-74 w-fit transition-transform duration-300 transform hover:scale-105'
                                    src='/images/buton2.png'
                                    alt=''
                                />
                        </a>
                    </div>
                    <div className='flex flex-col justify-center'>
                        <a className='h-fit w-fit'
                           href="https://b2b.bulutmarket.com.tr"
                        >
                                <img
                                    className='object-contain max-h-74 w-fit transition-transform duration-300 transform hover:scale-105'
                                    src='/images/buton1.png'
                                    alt=''
                                />
                        </a>
                    </div>
                </div>
            </section>

            <section className='pt-21 pb-28 flex flex-col items-center justify-center bg-bg_tertiary max-h-full dark:bg-indigo-950'>
                <div className=' max-w-8xl py-4 px-18 items-center flex flex-col '>
                    <div className='w-full flex flex-col text-center'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            {section3.title}
                        </h1>
                    </div>
                </div>

                <div className=' max-w-8xl px-4 w-full h-fit grid gap-4 grid-cols-3 pt-23 pb-4'>
                    <div className='flex flex-col justify-center w-full h-fit pt-10'>
                        <img
                            className='object-contain max-h-112 inline-flex'
                            src='/images/why_us.svg'
                            alt=''
                        >
                        </img>
                    </div>
                    <div className='grid grid-cols-2 col-span-2 justify-start ps-20 pe-6 gap-6'>
                        {whyBulutmarketItems?.map((whyBulutmarketItem, index) =>
                            <div className='[&:nth-child(odd)]:pe-7 [&:nth-child(even)]:ps-6'>
                                <h1 className='font-bold text-text_primary text-lg '>
                                    {whyBulutmarketItem.title}
                                </h1>
                                <p className='text-lg text-text_secondary md:text-lg lg:text-lg '>
                                    {whyBulutmarketItem.description}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className='flex flex-col items-center justify-center text-center bg-bg_primary max-h-full dark:bg-indigo-950'>
                <div className=' max-w-8xl px-4 grid grid-cols-3 pt-8 pb-13 divide-x divide-white'>
                {orangeBannerItems?.map((orangeBannerItem, index) =>
                     <AnimatedComponent
                     direction={`animate-fade-up animate-duration-500`}
                     className={`min-h-fit flex flex-col justify-start py-9 px-12`} >
                        <div className='flex flex-col justify-start'>
                            <h1 className='pb-2 font-bold text-white text-3xl'>
                                {orangeBannerItem.title}
                            </h1>
                            <p className=' text-lg text-white md:text-lg lg:text-lg'>
                                {orangeBannerItem.description}
                            </p>
                        </div>
                    </AnimatedComponent>
                )}
                </div>
            </section>

            <section className='pt-23 flex flex-col items-center justify-center bg-white max-h-full dark:bg-indigo-950'>
                <div className=' max-w-8xl pb-12 px-18 items-center flex flex-col '>
                    <div className='w-full flex flex-col mt-15 text-center'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            {section4.title1}
                        </h1>
                    </div>
                </div>

                <ProductRange/>
            </section>

            <section className='pb-32 flex flex-col items-center justify-center bg-white max-h-full dark:bg-indigo-950'>
                <div className=' max-w-8xl py-12 px-18 items-center flex flex-col '>
                    <div className='w-full flex flex-col mt-15 text-center'>
                        <h1 className='font-bold text-text_primary text-4xl '>
                            {section4.title2}
                        </h1>
                    </div>
                </div>

                <Brands/>
            </section>
        </>
    );
}
